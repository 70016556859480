import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';

import Img from "gatsby-image"

const Header = () => (
  <StaticQuery
    query={graphql`
    query HeroAssets {
      file(relativePath: {eq: "hero_shapes.png"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }    
    `}
    render={data => (
      <Hero className="container">
        <div className="row">
          <div className="col-11 col-lg-11 hero">
            <Img fluid={data.file.childImageSharp.fluid} alt="Colorful fun abstract shapes" />
          </div>
        </div>
        {/* Title that temporarily shows in Hero, then hides and another DropCap in Bio */}
        <div className="row">
          <DropCap className="col-sm-3 offset-sm-7">Hi there<span>,</span></DropCap>
        </div>
      </Hero>
    )}
  />
);

const Hero = styled.div`
  .hero {
    margin-left: 18px;
  }
`;

const DropCap = styled.h1`
  display: block;
  text-align: right;
  margin-bottom: 34px;
  margin-top: -170px;

  span {
    margin-right: -20px;
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    display: none;
  }
`;

export default Header;

import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Section } from '@components/global';
import Publication from '@common/Publication';

import ExtraShapes from '@images/articles/shapes_articles_bottom.svg';

const iconColors = ['#FF8947', '#FFD04A', '#86D4D2', '#D0B4F3'];
const lineColors = ['#FF9559', '#FFD04A', '#92D8D6', '#D5BBF4'];

class Articles extends React.Component {
  render() {
    return (
      <Section id="writing" className="container">
        {/* TODO: Add as a separate component outside of the Articles section so is not constrained by container class */}
        <Img fluid={this.props.fluidImg} alt="Separator before Articles"></Img>
        <div className="row justify-content-center">
          <SectionTitle className="col-6 offset-2">Writing</SectionTitle>
        </div>
        {/* Loop through publications */}
        {this.props.publications.map((pub, index) => (
          <Publication
            articles={pub.node.articles}
            publication={pub.node.title}
            pubUrl={pub.node.url}
            svgColor={iconColors[index % iconColors.length]}
            lineColor={lineColors[index % lineColors.length]}
          ></Publication>
        ))}
      </Section>
    );
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query siteTitleQueryAndArticlesQuery {
        allContentfulPublication(
          sort: { fields: [article___pubDate], order: DESC }
        ) {
          edges {
            node {
              title
              url
              articles {
                id
                title
                url
                pubDate
              }
            }
          }
        }
        file(relativePath: { eq: "separator_articles.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <Articles
        publications={data.allContentfulPublication.edges}
        fluidImg={data.file.childImageSharp.fluid}
      />
    )}
  />
);

const BgVertical = styled.div`
  height: 170%;
  /* width: 420px; */
  width: 100%;
  background-color: #fad7df;
  position: absolute;
  margin-top: 130px;

  @media (max-width: ${props => props.theme.screen.sm}) {
    height: 150%;
  }
`;

const BgHorizontal = styled.div`
  height: 565px;
  background-color: #fad7df;
  position: absolute;
  margin-top: 1400px;
  z-index: -1;

  @media (max-width: ${props => props.theme.screen.sm}) {
    height: 300px;
  }
`;

const SectionTitle = styled.h1`
  ${props => props.theme.font_size.xxlarger};
  margin-top: -100px;
  margin-bottom: 122px;

  @media (max-width: ${props => props.theme.screen.sm}) {
    ${props => props.theme.font_size.xlarge};
    /* TODO: Fix this */
    margin-left: -10%;
  }
`;

const Subtitle = styled.h3`
  margin-top: 10px;
`;

const FunShapes = styled.img`
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-top: 15%;
  }
`;

const CircleWords = styled.img`
  position: absolute;
  right: -30%;
  margin-top: 18%;
  overflow-x: hidden;

  @media (max-width: ${props => props.theme.screen.sm}) {
    display: none;
  }
`;

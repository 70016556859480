import React from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';

import { Section, Container } from '@components/global';

// Encode the data being sent to Netlify
const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

// Contact form
export default class Contact extends React.Component {
  state = {
    firstName: '',
    lastName: '',
    email: '',
    message: '',
  };

  handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  handleSubmit = event => {
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contact', ...this.state }),
    })
      .then(() => navigate('/thank-you/'))
      .catch(error => alert(error));
    event.preventDefault();
    // alert(`Welcome ${this.state.firstName} ${this.state.lastName}!`);
  };

  render() {
    return (
      <Section id="contact">
        <Container>
          <Title>
            <h3>// I'm all ears</h3>
            <h1>Contact me</h1>
          </Title>
          <StyledForm
            name="contact"
            method="POST"
            data-netlify="true"
            netlify-honeypot="bot-field"
            onSubmit={this.handleSubmit}
          >
            <input type="hidden" name="form-name" value="contact" />
            <input type="hidden" name="bot-field" />
            <RowOne className="row">
              <div className="col-md-5">
                <label>
                  First Name
                  <input
                    type="text"
                    name="firstName"
                    placeholder="Jane"
                    value={this.state.firstName}
                    onChange={this.handleInputChange}
                  />
                </label>
              </div>
              <div className="col-md-5 offset-md-1">
                <label>
                  Last Name
                  <input
                    type="text"
                    name="lastName"
                    placeholder="Austen"
                    value={this.state.lastName}
                    onChange={this.handleInputChange}
                  />
                </label>
              </div>
            </RowOne>
            <RowTwo className="row">
              <div className="col-md-5">
                <label>
                  Email
                  <input
                    type="email"
                    name="email"
                    placeholder="danceallnight@emma.com"
                    value={this.state.email}
                    onChange={this.handleInputChange}
                  />
                </label>
              </div>
              <div className="col-md-5 offset-md-1">
                <label>
                  Message
                  <textarea
                    type="text"
                    name="message"
                    rows="4"
                    placeholder="meow"
                    value={this.state.message}
                    onChange={this.handleInputChange}
                  />
                </label>
              </div>
            </RowTwo>
            <div className="row">
              <button
                type="submit"
                className="btn btn-primary col-md-5 offset-md-6"
              >
                Send message
              </button>
            </div>
          </StyledForm>
        </Container>
      </Section>
    );
  }
}

export const Title = styled.div`
  h3 {
    margin-bottom: 28px;

    @media (max-width: ${props => props.theme.screen.sm}) {
      font-size: 20px;
      line-height: 33px;
      letter-spacing: 2px;
    }
  }
  h1 {
    margin-bottom: 80px;

    @media (max-width: ${props => props.theme.screen.sm}) {
      font-size: 64px;
      line-height: 58px;
      letter-spacing: -4px;
    }
  }
`;

export const StyledForm = styled.form`
  input,
  textarea,
  label {
    display: block;
    width: 100%;
  }

  label {
    margin-bottom: 3px;
    font-family: ${props => props.theme.font.link};
    color: ${props => props.theme.color.purple.regular};
  }

  input,
  textarea {
    border: none;
    border-bottom: 1px dashed #ab73f1;
    outline: none;
    padding-bottom: 12px;
    color: #66458e;
    font-size: 24px;
  }

  button {
    margin-bottom: 96px;
    background: #ab73f1;
    box-shadow: 0px 4px 10px rgba(54, 17, 100, 0.25);
    border-radius: 10px;
    border: none;
  }
  button:hover {
    background-color: #8a5bc6;
  }
`;

export const RowOne = styled.div`
  margin-bottom: 60px;

  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-bottom: 0px;
    div {
      margin-bottom: 30px;
    }
  }
`;

export const RowTwo = styled.div`
  margin-bottom: 60px;

  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-bottom: 0px;
    div {
      margin-bottom: 30px;
    }
  }
`;

import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Section } from '@components/global';

const Substack = () => (
  <StaticQuery
    query={graphql`
      query SubstackAssets {
        file(relativePath: { eq: "bg_doodles_2.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <Section
        id="subStack"
        style={{
          backgroundColor: '#FAD7DF',
          paddingBottom: '100px',
          position: 'relative',
        }}
      >
        <div>
          <Img
            fluid={data.file.childImageSharp.fluid}
            alt="background doodles"
            style={{
              position: 'absolute',
              width: '732px',
              height: '762px',
              top: '0',
              left: '25%',
            }}
          />
        </div>
        <iframe
          src="https://rachroobearrecommends.substack.com/embed"
          width="480"
          height="320"
          style={{
            position: 'relative',
            border: '1px solid #EEE',
            background: 'white',
            margin: '0 auto',
            display: 'block',
            zIndex: '2',
          }}
          frameborder="0"
          scrolling="no"
        ></iframe>
      </Section>
    )}
  />
);

export default Substack;

import React from 'react';
import ExternalLink from '@common/ExternalLink';
import styled from 'styled-components';

class Publication extends React.Component {
  render() {
    return (
      <div className="row">
        <div className="col-11 col-sm-10 offset-sm-1 col-lg-10 offset-lg-2">
          <Wrapper>
            <ArticleIcon
              width="120"
              height="112"
              viewBox="0 0 120 112"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g style={{ mixBlendMode: 'multiply', opacity: '0.9' }}>
                <path
                  d="M112.428 56C139.598 112 87.2601 112 56.214 112C25.1679 112 0 86.9279 0 56C0 25.0721 25.1679 0 56.214 0C84.321 36.4 112.428 25.0721 112.428 56Z"
                  fill={this.props.svgColor}
                />
              </g>
            </ArticleIcon>
            <PublicationTitle>{this.props.publication}</PublicationTitle>
            <Line style={{ background: this.props.lineColor }}></Line>
            {this.props.articles.map(article => (
              <ExternalLink href={article.url}>
                <Title className="col-11 offset-1 col-sm-10 offset-sm-2 col-md-12 offset-md-0 col-lg-10 offset-lg-2">
                  {article.title}
                </Title>
              </ExternalLink>
            ))}
          </Wrapper>
        </div>
      </div>
    );
  }
}

export const Wrapper = styled.div`
  /* display: flex;
  justify-content: center;
  align-items; center;
  margin: 32px 0 46px 0; */

  text-align: right;
  /* height: 194px; */
  margin-bottom: 80px;
  /* padding-bottom: 10%; */
  /* display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end; */

  p {
    margin-bottom: 0px;
  }

  /* @media (max-width: ${props => props.theme.screen.sm}) {
    margin: 32px 155px 46px 0;
  } */

/* Matches up to Boostraps xs class */
  /* @media (max-width: ${props => props.theme.screen.sm}) {
    max-width: 450px;
  } */
`;

export const PublicationTitle = styled.h2`
  font-family: 'Recoleta-Regular';
  font-style: normal;
  font-weight: normal;
  font-size: 64px;
  /* line-height: 24px; */
  /* or 37% */
  text-align: right;
  color: #000000;
  margin: 13px 0px 20px 0px;
  /* Necessary to stack Publication on top of SVG in drawing order */
  position: relative;

  @media (max-width: ${props => props.theme.screen.sm}) {
    font-size: 32px;
    line-height: 24px;
  }
`;

export const Title = styled.p`
  font-family: 'Recoleta-Regular';
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 45px;
  /* or 141% */
  text-align: right;
  color: #000000;
  padding-right: 0px;
  margin-top: 21px;

  @media (max-width: ${props => props.theme.screen.sm}) {
    font-size: 18px;
    line-height: 24px;
  }
`;

export const Line = styled.div`
  width: 100%;
  height: 10px;
  background: #ff9559;
  mix-blend-mode: multiply;
  border-radius: 5px;
`;

export const ArticleIcon = styled.svg`
  position: absolute;
  width: 120px;
  height: 112px;
  left: calc(40% - 120px / 2 - 158.5px);
  top: 18px;

  /* Dynamically space the floating SVG shape based on lenth of title */
  @media (max-width: ${props =>
      props.theme.screen.md}) and (min-width: ${props =>
      props.theme.screen.sm}) {
    top: ${props => (props.longTitle ? '68px' : '18px')};
  }
`;

// OLD STYLES
export const Circle = styled.div`
  width: 244px;
  height: 244px;
  background-color: #ff8947;
  border-radius: 50%;
  mix-blend-mode: multiply;
  display: inline-block;
`;

export const Pub = styled.div`
  height: 244px;
  width: 305px;
  margin-left: 138px;

  p {
    padding: 95px 0;
    ${props => props.theme.font_size.xlarge};
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 150px;
    margin-left: 70px;

    p {
      padding: 105px 0;
      ${props => props.theme.font_size.article};
    }
  }
`;

export default Publication;

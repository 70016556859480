import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Section } from '@components/global';

const Bio = () => (
  <StaticQuery
    query={graphql`
      query BioAssets {
        file(relativePath: { eq: "portrait_rjz.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="bio" className="container">
        <div className="row">
          <DropCap className="col-6 offset-1">
            Hi there<span>,</span>
          </DropCap>
        </div>
        <Intro className="row">
          <div className=" funBorder col-10 offset-1 col-lg-8 offset-lg-1">
            <p className="intro">
              Hi! I’m Rachel. Though I’m not much of a hat person, I wear many
              hats at work. I’m a writer, creative writing instructor,
              copywriter, and branding professional, assuming we can all agree
              that bathrobes are the attire of a modern-day professional.
            </p>
          </div>
        </Intro>
        <MainBio className="row">
          <div className="col-12 col-lg-6 image-container">
            <Img
              fluid={data.file.childImageSharp.fluid}
              width="800"
              alt="Rachel Zarrow portrait"
            />
          </div>
          <div className="col-10 offset-1 col-lg-5 offset-lg-1">
            <p>
              When I’m not playing with words, I can be found playing with my
              dogs, cooking, painting, or taking a polar bear plunge.
            </p>
            <p>
              I live in San Francisco, where I sometimes enjoy the views, but
              more often the fog. I frequently contribute book reviews to the{' '}
              <a href="https://datebook.sfchronicle.com/search/rachel+zarrow">
                San Francisco Chronicle
              </a>
              , and I’m a volunteer with{' '}
              <a href="https://www.prisonrenaissance.org/empowerment-avenue-writers-cohort">
                Empowerment Avenue
              </a>
              . To keep up with my latest reading recs or musings on Lil Nas X,{' '}
              <a href="https://rachroobearrecommends.substack.com/">
                click here
              </a>
              .
            </p>
            <h3>Accolades and Affiliations</h3>
            <ul>
              <li>Voted “Least Chill” by my BFFs for 30 years and counting</li>
              <li>
                Founder of Fellowship of Jewish Athletes at my Episcopalian high
                school, circa 2006
              </li>
              <li>
                Self-diagnosed <a href="https://hsperson.com/">HSP</a>
              </li>
              <li>
                Myers-Brigg ENFJ (like{' '}
                <a href="https://www.16personalities.com/enfj-personality">
                  Oprah
                </a>
                )
              </li>
            </ul>
            <br />
            {/* TODO: Add smooth scrolling with Scrollspy */}
            <p>
              If you're looking to hire me to make you or your brand sound _____ (funny, smart,
              confident, attractive) visit <a href="https://www.seconddraftstudio.com/" target="_blank">Second Draft Studio</a>. For all
              other inquiries, <a href="#contact">click here</a>.
            </p>
          </div>
        </MainBio>
        <Education className="row">
          <div className="funBorder col-10 offset-1 col-lg-4 offset-lg-4">
            <p>
              Education:
              <br />
              MFA Creative Writing, <br /> UC Riverside Palm Desert 2019
            </p>
            <p>
              BA Sociology, Minor Italian, <br /> Stanford University 2013
            </p>
            <p id="represent">
              Represented by{' '}
              <a href="http://www.thebookgroup.com/jamie-carr">Jamie Carr</a>{' '}
              <br /> of the Book Group
            </p>
          </div>
        </Education>
      </Section>
    )}
  />
);

const DropCap = styled.h1`
  display: none;
  text-align: left;
  margin-bottom: 34px;
  ${props => props.theme.font_size.xxlarge};

  @media (max-width: ${props => props.theme.screen.sm}) {
    display: block;
  }
`;

const Intro = styled.div`
  margin-bottom: 95px;
  text-align: justify;
  text-justify: inter-word;

  .funBorder {
    border-bottom: 1px dashed #8a5bc6;
  }
`;

const MainBio = styled.div`
  text-align: justify;
  text-justify: inter-word;

  .image-container {
    margin-top: -90px;
  }

  a:link,
  a:visited {
    border-bottom: 1px solid ${props => props.theme.color.purple.light};
    text-decoration: none;
    color: ${props => props.theme.color.purple.regular};
  }

  a:hover {
    color: ${props => props.theme.color.purple.light};
  }
`;

const Education = styled.div`
  text-align: justify;
  text-justify: inter-word;

  .funBorder {
    padding-top: 45px;
    border-top: 1px dashed #8a5bc6;
  }

  p {
    color: ${props => props.theme.color.purple.light};
    text-align: right;
  }

  #represent {
    color: ${props => props.theme.color.purple.regular};
  }

  a:link,
  a:visited {
    border-bottom: 1px solid ${props => props.theme.color.purple.light};
    text-decoration: none;
    color: ${props => props.theme.color.purple.regular};
  }

  a:hover {
    color: ${props => props.theme.color.purple.light};
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-top: 60px;
  }
`;

export default Bio;
